export const editorConfiguration = {
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      'fontColor',
      'fontSize',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      'blockQuote',
      // "code",
      'imageUpload',
      'link',
      'mediaEmbed',
      'MathType',
      'ChemType',
      'horizontalLine',
      // "imageResize",
      // "imageStyle:full",
      // "imageStyle:side",
      // "indent",
      // "outdent",
      // "insertTable",
      // "tableColumn",
      // "tableRow",
      // "mergeTableCells",
      '|',
      'undo',
      'redo',
      'wproofreader',
    ],
  },
  image: {
    toolbar: [
      'imageResize',
      'imageStyle:full',
      'imageStyle:side',
      '|',
      'imageTextAlternative',
    ],
  },
  wproofreader: {
    lang: 'id_ID', // set the default language
    serviceId: 'eArWtWjJDDh5Zy1', // the activation keyfor the Cloud-based version only
    srcUrl:
      'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
  },
  language: 'en',
}

export const editorConfigurationWithoutImage = {
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      'fontColor',
      'fontSize',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      'blockQuote',
      'link',
      'MathType',
      'ChemType',
      '|',
      'undo',
      'redo',
      'wproofreader',
    ],
  },
  wproofreader: {
    lang: 'id_ID',
    serviceId: 'eArWtWjJDDh5Zy1',
    srcUrl:
      'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
  },
  language: 'en',
};


export const minimalConfig = {
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      'fontColor',
      'fontSize',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      'blockQuote',
      // "code",
      'imageUpload',
      'horizontalLine',
      'link',
      'mediaEmbed',
      '|',
      'undo',
      'redo',
    ],
  },
  image: {
    toolbar: [
      'imageResize',
      'imageStyle:full',
      'imageStyle:side',
      '|',
      'imageTextAlternative',
    ],
  },
  wproofreader: {
    lang: 'id_ID', // set the default language
    serviceId: 'eArWtWjJDDh5Zy1', // the activation keyfor the Cloud-based version only
    srcUrl:
      'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
  },
  language: 'en',
}

export const simpleConfig = {
  toolbar: {
    items: [
      'wproofreader',
      'heading',
      'bold',
      'italic',
      'fontColor',
      'fontSize',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      'blockQuote',
      '|',
      'undo',
      'redo',
      'wproofreader',
    ],
  },
  wproofreader: {
    lang: 'id_ID', // set the default language
    serviceId: 'eArWtWjJDDh5Zy1', // the activation keyfor the Cloud-based version only
    srcUrl:
      'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
  },
  image: {
    toolbar: [
      'imageResize',
      'imageStyle:full',
      'imageStyle:side',
      '|',
      'imageTextAlternative',
    ],
  },
  language: 'en',
}

export const customConfig = (toolBarItems = []) => {
  return {
    toolbar: {
      items: toolBarItems,
    },
    image: {
      toolbar: [
        'imageResize',
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative',
      ],
    },
    wproofreader: {
      lang: 'id_ID', // set the default language
      serviceId: 'eArWtWjJDDh5Zy1', // the activation keyfor the Cloud-based version only
      srcUrl:
        'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
    },
    language: 'en',
  }
}

export const readonlyEditorConfig = {
  language: 'en',
  toolbar: false,
  isReadOnly: true,
  readOnly: true,
  disabled: true,
  isDisabled: true,
  wproofreader: {
    lang: 'id_ID', // set the default language
    serviceId: 'eArWtWjJDDh5Zy1', // the activation keyfor the Cloud-based version only
    srcUrl:
      'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
  },
  mediaEmbed: {
    extraProviders: [
      {
        name: 'tiktok',
        url: /https?:\/\/(?:www\.)?tiktok\.com\/@?[\w.-]+\/video\/(\d+)(?:\?.*)?$/,
        html: (match) => {
          // Transform the URL into an embeddable iframe
          const id = match[1]
          return (
            '<iframe src="https://www.tiktok.com/embed/' +
            id +
            '" ' +
            'style="border: none; width: 100%; height: 500px;"></iframe>'
          )
        }
      },
      {
        name: 'allow-all',
        url: /^.+/
      }
    ]
  },
  link: { addTargetToExternalLinks: true }
}
