import React, { useEffect } from 'react'

import usePreviewRapor, { PreviewRaporWrapper } from 'context/preview-rapor'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import HeaderRaporBlock from './header'
import RaporBlock from './block'
import Buttons from './partials/buttons'
import Status from './partials/status'
import Kop from './partials/header/kop'
import CkeditorReadonlyV2 from 'component/ckeditor-readonly/indexV2'

export function PreviewRaporBlockInsideWrapper({ mobile }) {
  /* =================================== Consume Local Storage ================================== */
  const address = JSON.parse(localStorage.getItem('profile'))?.city

  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================= Functions ======================================== */
  function formatDateNow() {
    const date = new Date()
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString('id-ID', options)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    rapor.fetch(mobile)
  }, [])

  /* ======================================== Components ======================================== */
  const Loader = () => {
    return (
      <div className="text-center">
        <Kop />
        <h3 className="mt-5">Memuat Raport Period</h3>
        <p>Mohon tunggu sejenak...</p>
        <div
          className="mt-4 mb-4 spinner-border text-primary"
          role="status"
        ></div>
      </div>
    )
  }

  const Preview = () => (
    <>
      <div
        className="row"
        ref={rapor.printEl}
      >
        <div className="col-12">
          <HeaderRaporBlock />

          {rapor.blocks?.map((block, block_idx) => (
            <RaporBlock
              key={`rapor_block_` + block_idx}
              dataSection={block.class.sections}
              studi={block.class.title}
              dataTranscript={
                rapor.res?.TranscriptScoresPeriod?.length
                  ? rapor.res?.TranscriptScoresPeriod.find(
                      (tsp) => tsp.class_id === block.class.id,
                    )
                  : null
              }
            />
          ))}
          <div style={{ pageBreakInside: 'avoid' }}>
            <div className="pt-5 ml-5 mr-5 mb-5">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>CATATAN PENTING</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <td className="word-wrap">
                      {rapor.res?.reportTransactionsperiod[0]?.note
                        .split('\n')
                        ?.map((paragraph) => (
                          <p style={{ wordBreak: 'break-word', textAlign: 'justify' }}>
                            {paragraph}
                          </p>
                        ))}
                    </td> */}
                    <CkeditorReadonlyV2 text={rapor.res?.reportTransactionsperiod[0]?.note} />
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="m-5">
              <table className="table table-note table-bordered">
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      className="printed-bgColor"
                    >
                      Note
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A</td>
                    <td>Achieved / Menguasai</td>
                  </tr>
                  <tr>
                    <td>D</td>
                    <td>Developing / Sudah memahami, perlu peningkatan</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Beginning / Masih perlu bantuan</td>
                  </tr>
                  <tr>
                    <td>P</td>
                    <td>Pengetahuan</td>
                  </tr>
                  <tr>
                    <td>K</td>
                    <td>Keterampilan</td>
                  </tr>
                  <tr>
                    <td>
                      <i 
                        className="fas fa-check" 
                        style={{ color: '#16a085'}}>
                      </i>
                    </td>
                    <td>Belum Dikoreksi</td>
                  </tr>
                  <tr>
                    <td>
                      <i 
                        className="fas fa-check" 
                        style={{ color: '#c0392b'}}>
                      </i>
                    </td>
                    <td>Belum Mengumpulkan</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="p-5"
              style={{ breakInside: 'avoid' }}
            >
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th colSpan={3}>
                      {rapor?.res?.Profiles?.[0]?.city || address || '___________________'}, {formatDateNow()}
                      <br></br>Mengetahui
                    </th>
                  </tr>
                  <tr>
                    <th>Kepala Sekolah</th>
                    <th>Guru Kelas</th>
                    <th>Orang Tua</th>
                  </tr>
                  <tr>
                    <td>
                      <img
                        style={{ maxWidth: '150px', height: 'auto' }}
                        src={rapor.res?.headMasterSignature}
                        alt="ttd wali kepala sekolah"
                      />
                    </td>
                    <td>
                      <img
                        style={{ maxWidth: '150px', height: 'auto' }}
                        src={rapor.res?.waliSignature}
                        alt="ttd wali kelas"
                      />
                    </td>
                    <td>
                      {
                        rapor.period?.transaction?.ortu
                        ?
                        rapor.period?.transaction?.ortu?.signature
                        ?
                        <img
                          style={{ maxWidth: '150px', height: 'auto' }}
                          src={rapor.period?.transaction?.ortu?.linkSignature}
                          alt="ttd ortu"
                        />
                        :
                        <div className="alert alert-success f-w-700">
                          <i className="fas fa-check-circle" /> Telah disetujui
                        </div>
                        :null
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {rapor.res?.headMaster?.length ? (
                        <>
                          <u>{rapor.res?.headMaster[0].name}</u>
                          <br></br>NIY: {rapor.res?.headMaster[0].nim}
                        </>
                      ) : (
                        <>
                          <br />
                          (..........................................)
                        </>
                      )}
                    </th>
                    <th>
                      {rapor.res?.waliKelas?.length ? (
                        <>
                          <u>{rapor.res?.waliKelas[0].name}</u>
                          <br />
                          NIY: {rapor.res?.waliKelas[0].nim}
                        </>
                      ) : (
                        <>
                          <br />
                          (..........................................)
                        </>
                      )}
                    </th>
                    <th>
                    {rapor.period?.transaction?.ortu?.name ? (
                        <>
                          <u>{rapor.period?.transaction?.ortu?.name}</u>
                        </>
                      ) : (
                        <hr className="bg-dark" />
                      )}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className={`col-6 offset-3 text-center ${mobile ? 'element-no-print' : ''}`}>
        <Status />
      </div>
      {!mobile && <Buttons />}
    </>
  )

  /* ========================================== Output ========================================== */
  return mobile ? (  
    rapor.res ? <Preview /> : <Loader />
  ) : (
    <TemplateAdmin>
      <Container>
        <Header pageName="Preview Rapor Block" />
        
        <div className="card" style={{ width: '1100px' }}>
          <div className="card-body">
            {rapor.res ? <Preview /> : <Loader />}
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default function PreviewRaporBlock({ mobile = false }) {
  return (
    <PreviewRaporWrapper>
      <PreviewRaporBlockInsideWrapper {...{mobile}}/>
    </PreviewRaporWrapper>
  )
}
